import { useRoute } from 'nuxt/app'
import { ref } from 'vue'
import { BreadcrumbsTexts } from '~/constants/general'

const useBreadCrumb = () => {
  const firstDirectory = ref(null)
  const linkFirstPart = ref(null)
  const dirsArr = ref(null)
  const urlDir = ref(null)
  const subTitleData = ref([])
  const staticBreadCrumb = ref(false)
  const breadcrumbData = ref([])
  const breadCrumbObject = ref({})
  const route = useRoute()

  const configPrevItems = (prevItems, autoReset?: boolean) => {
    if (autoReset) {
      breadcrumbData.value = []
    }

    const firstRoute =
      dirsArr.value.length <= 3 || dirsArr.value.includes('overview')
        ? null
        : `${firstDirectory.value}/overview/`
    prevItems.forEach((item, index) => {
      if (typeof item !== 'string') {
        breadcrumbData.value.push(item)
        return
      }

      breadcrumbData.value.push({
        label: item,
        url: index === 0 && !staticBreadCrumb.value ? firstRoute : null,
      })
    })
  }

  const getRegionsDirectory = () => {
    return ['/cathode/supply', '/anode/supply'].includes(urlDir.value) ? 'locations' : 'regions'
  }

  const configDataBreadCrumb = (autoReset?: boolean) => {
    if (autoReset) {
      subTitleData.value = []
    }

    Object.keys(BreadcrumbsTexts).forEach((key) => {
      if (key === 'prevItems') {
        configPrevItems(breadCrumbObject.value[key], autoReset)
        return
      }

      if (!breadCrumbObject.value[key]) return

      const breadCrumbItem = breadCrumbObject.value[key]

      if (!breadCrumbItem?.length) return

      const regionsDirectory = getRegionsDirectory()
      const isMultiple = breadCrumbItem.length > 1

      const items = breadCrumbItem.map((item) => {
        const directory = key === 'regions' ? regionsDirectory : key
        const url = item.id ? `${urlDir.value}/${directory}/${item.id}/` : null

        return {
          ...item,
          type: directory,
          url,
        }
      })

      subTitleData.value.push({
        label: BreadcrumbsTexts[key].multipleLabel,
        isMultiple,
        items,
      })
    })
  }

  const configBreadCrumb = (autoReset?: boolean) => {
    firstDirectory.value = /^\/[^/]+/.exec(route.path)[0]
    linkFirstPart.value = linkFirstPart.value !== '' ? linkFirstPart.value : firstDirectory.value
    dirsArr.value = route.path.split('/') // get array of the route path directories
    urlDir.value = dirsArr.value.slice(0, dirsArr.value.length - 3).join('/')
    configDataBreadCrumb(autoReset)
  }

  return { staticBreadCrumb, breadcrumbData, breadCrumbObject, subTitleData, configBreadCrumb }
}

export default useBreadCrumb
